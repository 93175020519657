import type { Candidate_Job_Workflow_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import {
  Role_Choices_Enum,
  Candidate_Availability_Choices_Enum,
  Candidate_Curation_Workflow_Status_Choices_Enum,
  Candidate_Curation_Rejected_Reason_Choices_Enum,
  Candidate_Curation_Workflow_Screener_Type_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
/* eslint-disable no-param-reassign */
import type { ComponentProps } from 'react';
import { supportedCountries, supportedCountriesReadableString } from 'candidate/shared';
import { myJobsSerializer } from 'candidate/shared/modules/job';
import { ActivityStatus } from 'candidate/shared/modules/job/MyJobs/myJobs.types';
import { serializeProfileCompletion } from 'candidate/shared/modules/profile/Profile.serializer';
import type { SelectHomePageInfoQuery } from './HomePage.types';
import type { HomePage, PersonalizedHeader } from './HomePage';
import onboardingWelcomeImageSource from './assets/onboarding-welcome.svg?url';
import onboardingCompleteProfileImageSource from './assets/onboarding-complete-profile.svg?url';
import onboardingReviewMatchesImageSource from './assets/onboarding-review-matches.svg?url';
import onboardingTalkTalentImageSource from './assets/onboarding-talk-talent.svg?url';

function selectPersonalizedHeaderProps({
  candidateInfo,
  isDesktop,
  roleNames,
  activityFeedStatuses,
  talentPartnerInfo,
  shouldShowBookMeetingWithTPButton,
  shouldShowAsyncInterviewButton,
  calendlyInviteeDate,
  hasTalentPartnerJobsToReview,
  hasActiveCalendlyInvitee,
}: {
  candidateInfo: {
    name: string;
    email: string;
    availability: Candidate_Availability_Choices_Enum | null;
    country: string | null;
    linkedinURL: string | null;
    resumeFilename: string | null;
    status?: Candidate_Curation_Workflow_Status_Choices_Enum | null;
    rejectedReason?: Candidate_Curation_Rejected_Reason_Choices_Enum | null;
    calendlyMeetingURL?: string | null;
    interviewRequestID?: number;
    asyncInterviewURL?: string | null;
  };
  talentPartnerInfo: {
    name: string;
    email: string;
  };
  hasTalentPartnerJobsToReview: boolean;
  shouldShowBookMeetingWithTPButton: boolean;
  shouldShowAsyncInterviewButton: boolean;
  isDesktop: boolean;
  roleNames: string[];
  hasActiveCalendlyInvitee: boolean;
  calendlyInviteeDate?: string;
  activityFeedStatuses: (ActivityStatus | Candidate_Job_Workflow_Status_Choices_Enum | undefined)[];
}): ComponentProps<typeof PersonalizedHeader> {
  const personalizedHeaderProps = [
    {
      shouldBeDisplayed:
        !candidateInfo.country || !supportedCountries.includes(candidateInfo.country),
      props: {
        title: 'Stay connected!',
        description: `Terminal places candidates who are currently living with valid work authorization in: ${supportedCountriesReadableString}. We will let you know when we expand.`,
      },
    },
    {
      shouldBeDisplayed:
        !roleNames.length ||
        !roleNames.some((roleName) =>
          // These are the current core roles
          [
            Role_Choices_Enum.Bed,
            Role_Choices_Enum.Fed,
            Role_Choices_Enum.Fsd,
            Role_Choices_Enum.Mod,
            Role_Choices_Enum.Enl,
            Role_Choices_Enum.Dsc,
          ].includes(roleName as Role_Choices_Enum),
        ),
      props: {
        title: 'Stay connected!',
        description: `We don't currently have openings that match the role you listed, but we're always expanding. Keep checking back for new roles.`,
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.availability === null ||
        candidateInfo.availability ===
          Candidate_Availability_Choices_Enum.InterestedAndAvailableLater_3PlusMonths,
      props: {
        title: 'Stay connected!',
        description: `We see you're not available for a new role yet, and our clients are looking to hire quickly. Please let us know and apply for roles if your timing changes.`,
        buttonCTA: 'Update Timing',
        redirectToURL: '/profile?s=personal-info',
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status ===
          Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenScheduling &&
        talentPartnerInfo.name &&
        shouldShowBookMeetingWithTPButton,
      props: {
        title: "Let's Talk!",
        description: `${talentPartnerInfo.name} is your personal Talent Partner and will help you in your journey to find a perfect job! Book a introductory chat to align your goals and needs.`,
        buttonCTA: `Book  Chat with ${talentPartnerInfo.name}`,
        candidateInfo: {
          email: candidateInfo.email,
          name: candidateInfo.name,
          calendlyMeetingURL: candidateInfo.calendlyMeetingURL,
          interviewRequestID: candidateInfo.interviewRequestID,
        },
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status ===
          Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenScheduled &&
        shouldShowAsyncInterviewButton,
      props: {
        title: "Let's Talk!",
        description:
          'We are excited to help you in your journey to find a perfect job! As a next step, help us learn more about you by answering a few questions with a self-guided video interview. Once complete, one of our talent team members will respond personally within 3 business days to provide information on next steps and possible job matches',
        buttonCTA: 'Start Interview',
        redirectToURL: candidateInfo.asyncInterviewURL,
      },
    },
    {
      shouldBeDisplayed: hasTalentPartnerJobsToReview,
      props: {
        title: 'Handpicked Jobs to Review',
        description: `${talentPartnerInfo.name} has selected matches for you, please let them know if you are interested`,
        buttonCTA: 'Review',
        redirectToURL: '/my-jobs',
      },
    },
    {
      shouldBeDisplayed: hasActiveCalendlyInvitee,
      props: {
        title: 'Congratulations!',
        description: `Thank you for scheduling a screener with us. Our talent team has emailed you and is looking forward to meeting you on ${calendlyInviteeDate}.`,
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status === Candidate_Curation_Workflow_Status_Choices_Enum.Rejected &&
        [
          Candidate_Curation_Rejected_Reason_Choices_Enum.FailedSkillsTest,
          Candidate_Curation_Rejected_Reason_Choices_Enum.TooJunior,
          Candidate_Curation_Rejected_Reason_Choices_Enum.NotIc,
          Candidate_Curation_Rejected_Reason_Choices_Enum.Other,
          Candidate_Curation_Rejected_Reason_Choices_Enum.WorkAuthorization,
          Candidate_Curation_Rejected_Reason_Choices_Enum.JobHopper,
          Candidate_Curation_Rejected_Reason_Choices_Enum.LowTerminalScore,
        ].includes(candidateInfo.rejectedReason as Candidate_Curation_Rejected_Reason_Choices_Enum),
      props: {
        title: 'Thank you!',
        description: `Thank you for submitting your application. We don't have the perfect role for you right now, but we will continue to review your application and will reach out if we find a good fit.`,
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status === Candidate_Curation_Workflow_Status_Choices_Enum.Rejected &&
        [
          Candidate_Curation_Rejected_Reason_Choices_Enum.ValuableProfile,
          Candidate_Curation_Rejected_Reason_Choices_Enum.NotCoreFocus,
        ].includes(candidateInfo.rejectedReason as Candidate_Curation_Rejected_Reason_Choices_Enum),
      props: {
        title: 'Stay connected!',
        description:
          'Your experience is impressive, but none of our roles are a perfect match. Please apply for new jobs you see and our talent team will reach out with any new opportunities.',
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status === Candidate_Curation_Workflow_Status_Choices_Enum.Accepted,
      props: {
        title: 'Congratulations!',
        description:
          'Thank you for speaking to our talent team about your application. We are interested in your profile to pass along to open roles. Check your email to see next steps.',
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status === Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenNoShow,
      props: {
        title: 'We missed you!',
        description:
          'Unfortunately we did not hear back for our request for a phone call. Please email our talent team back if you wish to reschedule or complete a video screen on your own time.',
        buttonCTA: 'Record Video',
        redirectToURL: 'https://app.hireflix.com/public-application/62310daff777a1dd36d8c726',
      },
    },
    {
      shouldBeDisplayed: activityFeedStatuses.some((activity) => activity === ActivityStatus.Offer),
      props: {
        title: 'Congratulations!',
        description:
          'Congratulations! You are currently in the offer stages with one of the roles you have been interviewing for. Your Talent Partner will relay any important information and answer any questions.',
      },
    },
    {
      shouldBeDisplayed:
        !!activityFeedStatuses.length &&
        activityFeedStatuses.every((activity) => activity === ActivityStatus.Declined),
      props: {
        title: 'Stay Connected!',
        description:
          "We currently don't have any jobs for you, please keep checking back and look out for emails from your Talent Partner for any new opportunities that come up.",
      },
    },
    {
      shouldBeDisplayed: activityFeedStatuses.some(
        (activity) => activity === ActivityStatus.InReview,
      ),
      props: {
        title: 'In Review',
        description:
          'You are currently being reviewed for the roles you and your Talent Partner have discussed. They will reach out when there is an update on your status associated to each role and company.',
      },
    },
    {
      shouldBeDisplayed: activityFeedStatuses.some(
        (activity) => activity === ActivityStatus.Interview,
      ),
      props: {
        title: 'In Interview',
        description:
          'You are currently in the interview process for one or more open roles. Your Talent Partner will update you on next steps. Good luck!',
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status === Candidate_Curation_Workflow_Status_Choices_Enum.NeedsMoreInfo,
      props: {
        title: 'Share more details',
        description:
          'The talent team needs more information to accurately assess your application. Please go to your profile, or check the email from your talent team for details.',
        buttonCTA: 'Go to Profile',
        redirectToURL: '/profile',
      },
    },
    {
      shouldBeDisplayed:
        candidateInfo.status ===
        Candidate_Curation_Workflow_Status_Choices_Enum.TechnicalAssessmentRequested,
      props: {
        title: 'Technical Assessment',
        description:
          'Our talent team has requested a technical assessment to better understand your skills. Please check your email for details.',
        buttonCTA: 'Take Assessment Now',
        redirectToURL: '/assessments',
      },
    },
    {
      shouldBeDisplayed: !candidateInfo.linkedinURL && !isDesktop,
      props: {
        title: `What's Next?`,
        description: 'Add to your profile so our talent team can assess your application sooner.',
        buttonCTA: 'Add LinkedIn Profile',
        redirectToURL: '/profile?s=social-profile&tc=hpcta',
      },
    },
    {
      shouldBeDisplayed: !candidateInfo.resumeFilename && isDesktop,
      props: {
        title: `What's Next?`,
        description: 'Add to your profile so our talent team can assess your application sooner.',
        buttonCTA: 'Add Resume',
        redirectToURL: '/profile?s=resume&tc=hpcta',
      },
    },
    {
      shouldBeDisplayed: true,
      props: {
        title: `What's Next?`,
        description:
          'Thanks for filling out your profile. Our talent team is evaluating your application and will email you in the next 3 days with next steps. Browse our open roles while you wait or connect with us using the chat button at the bottom of the screen',
      },
    },
  ].find(({ shouldBeDisplayed }) => shouldBeDisplayed);

  return personalizedHeaderProps?.props as ComponentProps<typeof PersonalizedHeader>;
}

export function serializerForHomePage({
  data,
  isDesktop,
  candidateEmail,
}: {
  data: SelectHomePageInfoQuery;
  isDesktop: boolean | undefined;
  candidateEmail: string;
}): {
  candidateID: number;
  onboardingSlides: ComponentProps<typeof HomePage>['onboardingSlides']['slides'];
  personalizedHeaderProps: ComponentProps<typeof PersonalizedHeader>;
  talentPartnerInfo: ComponentProps<typeof HomePage>['talentPartnerInfo'] | null;
  talentPartnerJobRecommendations:
    | ReturnType<typeof myJobsSerializer>['talentPartnerSelectedJobs_toReview']
    | null;
  appliedJobsSummary: {
    numberOfApplicationsInProgress: number;
    description: string;
  };
  profileCompletion: ReturnType<typeof serializeProfileCompletion>;
} {
  if (data.candidate.length === 0) {
    throw new Error('Candidate not found');
  }

  const candidate = data.candidate[0] as SelectHomePageInfoQuery['candidate'][number];
  const roleNames = candidate.candidate_roles.map(({ role }) => role);
  const talentPartnerData = candidate.owner_person?.icims_people[0];

  const { appliedJobs, archivedJobs, talentPartnerSelectedJobs_toReview } = myJobsSerializer({
    data,
  });

  // Transform calendly meeting date to a more readable (humanize) format
  const humanizeCalendlyMeetingDate = (date: string) => {
    const calendlyDate = new Date(date);
    const dateString = calendlyDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour12: true,
    });

    const hourString = calendlyDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    });

    return `${dateString} @ ${hourString}`; // eg: "Oct 1, 2020 @ 10:00 AM EDT"
  };

  return {
    candidateID: candidate.id,
    onboardingSlides: [
      {
        title: 'What to Expect?',
        description:
          "Welcome to Terminal, where we connect remote engineers to great U.S. technology jobs. Here's how it works:",
        imageSRC: onboardingWelcomeImageSource,
      },
      {
        title: 'Complete Your Profile',
        description:
          'The first step is to complete your profile, including work history, education, and skills. This will allow you to be matched to great jobs and get you in front of our talent team.',
        imageSRC: onboardingCompleteProfileImageSource,
      },
      {
        title: 'Review Your Job Matches',
        description:
          "We'll match you to great jobs from our exclusive set of U.S. companies that match your profile and background. Apply with one-click!",
        imageSRC: onboardingReviewMatchesImageSource,
      },
      {
        title: 'Connect With Us',
        description:
          "Our talent team will review your profile and interests to see what's a great fit. We'll get back to you within 3 business days, guaranteed.",
        imageSRC: onboardingTalkTalentImageSource,
      },
    ],
    personalizedHeaderProps: selectPersonalizedHeaderProps({
      candidateInfo: {
        availability: candidate.availability,
        country: candidate?.country_choice?.name || null,
        linkedinURL: candidate.linkedin_url,
        name: `${candidate?.first_name ?? ''} ${candidate?.last_name ?? ''}`.trim(),
        email: candidateEmail,
        rejectedReason: candidate?.furthest_candidate_curation_workflow?.rejected_reason,
        resumeFilename: candidate.resume_filename,
        status: candidate?.furthest_candidate_curation_workflow?.status,
        calendlyMeetingURL: candidate.interview_requests[0]?.invite_link,
        interviewRequestID: candidate.interview_requests[0]?.id,
        asyncInterviewURL: candidate?.furthest_candidate_curation_workflow?.async_interview_url,
      },
      calendlyInviteeDate: humanizeCalendlyMeetingDate(
        candidate.interview_requests[0]?.calendly_invitees[0]?.calendly_events[0]?.start_time,
      ),
      hasActiveCalendlyInvitee:
        !!candidate.interview_requests[0]?.calendly_invitees[0]?.calendly_events[0]?.start_time,
      activityFeedStatuses: [...appliedJobs, ...archivedJobs].map(
        (job) => job.summary.badge?.label,
      ),
      hasTalentPartnerJobsToReview: !!talentPartnerSelectedJobs_toReview.length,
      talentPartnerInfo: {
        name: `${talentPartnerData?.firstname ?? ''} ${talentPartnerData?.lastname ?? ''}`.trim(),
        email: talentPartnerData?.email,
      },
      shouldShowBookMeetingWithTPButton:
        // Check that candidate hasn't book yet a meeting with TP
        !(
          (
            candidate.interview_requests[0]?.calendly_invitees &&
            candidate.interview_requests[0]?.calendly_invitees.length > 0
          )
          // Check that there is an existing link to book a meeting with TP
        ) && !!candidate.interview_requests[0]?.invite_link,
      shouldShowAsyncInterviewButton:
        candidate?.furthest_candidate_curation_workflow?.screener_type ===
        Candidate_Curation_Workflow_Screener_Type_Choices_Enum.AsyncInterview,
      isDesktop: !!isDesktop,
      roleNames,
    }),
    talentPartnerInfo:
      talentPartnerData?.firstname && talentPartnerData?.email // is talent partner assigned
        ? {
            fullName: `${talentPartnerData.firstname ?? ''} ${
              talentPartnerData.lastname ?? ''
            }`.trim(),
            email: talentPartnerData.email,
            image: null, // temporary null until backend fixes this
            // TODO: add image once is supported by the BE
          }
        : null,
    talentPartnerJobRecommendations: talentPartnerSelectedJobs_toReview,
    appliedJobsSummary: {
      numberOfApplicationsInProgress: appliedJobs.length,
      description:
        appliedJobs.length > 0
          ? 'Check the progress in all your applied jobs.'
          : "You haven't applied to any jobs yet.",
    },
    profileCompletion: serializeProfileCompletion({
      fullName: candidate?.first_name || candidate?.last_name || '',
      availability: candidate?.availability,
      location: candidate?.country_choice?.name || candidate?.city,
      email: candidate?.email,
      roles: candidate?.candidate_roles,
      socialProfileLinks: {
        linkedin_url: candidate?.linkedin_url,
        github_url: candidate?.github_url,
        other_url: candidate?.other_url,
      },
      workExperiences: candidate?.candidate_work_experiences,
      educations: candidate?.candidate_educations,
      skillGroups: candidate?.candidate_skills,
      resumeFilename: candidate?.resume_filename,
      desiredSalaryAmount: candidate?.candidate_curation_detail?.desired_salary_amount,
    }),
  };
}
